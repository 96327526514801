import { consola } from "consola";
import { useNewsUtils } from "./useNewsUtils";
import { TypeNewsCategoryDto, TypeNewsDto } from "./news.type";

export const useNews = () => {

    const globalSettings = inject("globalSettings");
    const { getItems } = useDirectusItems();
    const { calTotalPage } = useParseData();
    const { generateNewsDto, generateNewsCategoryDto } = useNewsUtils(globalSettings);

    let newsFields =
        [
            "id",
            "status",
            "tags",
            "date_created",
            "category.id",
            "category.status",
            "category.title",
            "category.slug",
            "title",
            "intro",
            "slug",
            "thumbnail",
        ]
    const getNewsCategories = async () => {
        return getItems({
            collection: "news_categories",
            params: {
                fields: [
                    "id",
                    "status",
                    "sort",
                    "title",
                    "slug",
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        }
                    ]
                },
            }
        }).then((data: any) => {
            return data.map((ite: any) => generateNewsCategoryDto(ite) as TypeNewsCategoryDto);
        }).catch(e => {
            consola.error("Error: getNewsCategories!");
            consola.debug(e);
            return {};
        })
    }


    const getNewsDetailBySlug = async (options: {
        slug: string,
        seo_fields: Array<any>
    }) => {
        return getItems({
            collection: "news",
            params: {
                fields: [
                    "id",
                    "status",
                    "date_created",
                    "sort",
                    "tags",
                    "category.id",
                    "category.status",
                    "category.title",
                    "category.slug",
                    "for_agency",
                    "title",
                    "content",
                    "intro",
                    "description",
                    "slug",
                    "thumbnail",
                    'related_news.*',
                    'related_news.related_news_id.id',
                    'related_news.related_news_id.date_created',
                    'related_news.related_news_id.title',
                    'related_news.related_news_id.thumbnail',
                    'related_news.related_news_id.slug',
                    'related_news.related_news_id.intro',
                    'related_news.related_news_id.category.id',
                    'related_news.related_news_id.category.title',
                    'related_news.related_news_id.category.slug',
                    'seo_detail.*',
                    ...options.seo_fields || []
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            }
                        },
                        {

                            slug: options.slug
                        }
                    ]
                },
            }
        }).then((data: any) => {
            return { model: generateNewsDto(data[0]) as TypeNewsDto };
        }).catch(e => {
            consola.error("Error: getNewsDetail!");
            consola.debug(e);
            return {};
        })
    }

    const getRelatedNews = async (options: {
        slug: string | number,
        query?: { limit: any }
    }) => {

        const category: any = await getItems({
            collection: "news_categories",
            params: {
                fields: ['id', 'status'],
                filter: {
                    status: { _eq: 'published' }
                }
            }
        })

        return getItems({
            collection: "news",
            params: {
                fields: [...newsFields],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            },

                        },
                        {
                            slug: {
                                _neq: options?.slug,
                            }
                        },
                        {
                            category: {
                                _eq: +category.id
                            }
                        }
                    ]
                },
                sort: ["-date_created"],
                limit: options?.query?.limit || 6,
            }
        })
            .then((res: any) => {
                return res.map((ite: any) => {
                    return generateNewsDto(ite) as TypeNewsDto;
                });
            }).catch((e: any) => {
                consola.error("Error: getRelatedNews!");
                consola.debug(e);
                return [];
            })
    }

    const getNewsByCategory = async (options: {
        slug?: string | number,
        id?: string | number,
        query: { limit: any, page: any }
    }) => {

        let and: any = [{ status: { _eq: 'published' } }]
        if (options.slug) {
            and.push({
                category: {
                    slug: {
                        _eq: options.slug
                    }
                }
            })
        }
        if (options.id) {
            and.push({
                category: {
                    id: {
                        _eq: options.id
                    }
                }
            })
        }

        return await getItems({
            collection: "news",
            params: {
                fields: [...newsFields],
                filter: {
                    _and: [...and]
                },
                limit: options.query.limit || 12,
                page: options.query.page || 1,
                meta: 'filter_count',
                sort: ['hero', 'featured', '-date_created']
            }

        })
            .then((res: any) => {

                return {
                    news: res.data.map((ite: any) => generateNewsDto(ite) as TypeNewsDto),
                    total_page: calTotalPage(+res.meta.filter_count, options?.query?.limit || 12),
                    total_count: res.meta.filter_count,
                    current_page: options.query.page || 1
                }

            }).catch((e: any) => {
                consola.error("Error: getNewsByCategory!");
                consola.debug(e);
                console.log(e)
                return [];
            })
    }

    const getRecentNews = async (options: {
        query?: { limit: any }
    }) => {

        return getItems({
            collection: "news",
            params: {
                fields: [
                    "id",
                    "status",
                    "tags",
                    "title",
                    "intro",
                    "description",
                    "slug",
                    "thumbnail",
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: "published"
                            },

                        },
                    ]
                },
                sort: ["-date_created"],
                limit: options?.query?.limit || 6,
            }
        })
            .then((res: any) => {
                return res.map((ite: any) => {
                    return generateNewsDto(ite) as TypeNewsDto;
                });
            }).catch((e: any) => {
                consola.error("Error: getRecentNews!");
                consola.debug(e);
                return [];
            })
    }

    const searchHashtag = async (text?: string, options?: {
        query: { limit: any, page: any }
    }): Promise<Array<TypeNewsDto> | any> => {
        let query: any = {};
        if (text && text !== '') {
            query = {
                collection: 'news',
                params: {
                    fields: [...newsFields],
                    filter: {
                        _and: [
                            {
                                status: {
                                    _eq: 'published'
                                }
                            },
                            {
                                tags: {
                                    _contains: text
                                }
                            },

                        ]
                    },
                    page: options?.query.page || 1,
                    limit: options?.query.limit || -1,
                    meta: ["filter_count"]
                }
            };
        }

        return getItems({
            ...query
        })
            .then(({ data, meta }: any) => {

                if (meta.filter_count !== 0) {
                    return {
                        news: data.map((ite: any) => generateNewsDto(ite)),
                        total_page: calTotalPage(+meta.filter_count, options?.query.limit || 12),
                        total_result: meta.filter_count,
                        current_page: options?.query.page || 1,
                    }
                } else {
                    return {
                        news: [],
                        total_page: 0,
                        total_result: 0,
                    }
                }
            }).catch(e => {
                consola.error("Error: searchHashtag!");
                consola.debug(e);
                return {};
            })
    }

    return {
        getNewsDetailBySlug,
        getRelatedNews,
        getRecentNews,
        getNewsCategories,
        getNewsByCategory,
        searchHashtag
    }
}
